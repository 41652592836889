import * as React from "react";
import { throttle } from "throttle-debounce";
import { useCoinsPrices } from "../hooks/query-hooks/use-coin-price";
import { SiteContext } from "./site-context";
import { currencyReducer, initialCurrencyState } from "./store/currency";

const SiteProvider: React.FC = ({ children }) => {
  const currency = React.useReducer(currencyReducer, initialCurrencyState);

  const [isMobile, setMobile] = React.useState<boolean>(true);
  const [isDarkmode, setDarkmode] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (window) {
      const checkDeviceSize = () => {
        const windowSize = window.innerWidth;
        if (windowSize < 767) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      };
      checkDeviceSize();
      window.addEventListener("resize", throttle(500, checkDeviceSize));

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        setDarkmode(true);
      }
    }
  }, []);

  const { data: exchangeRates } = useCoinsPrices({
    ids: ["tether", "bitcoin"],
  });

  const store = {
    isMobile,
    isDarkmode,
    currency,
    usdExchangeRate: exchangeRates?.tether?.[currency[0]],
    btcExchangeRate: exchangeRates?.bitcoin?.[currency[0]],
  };

  return <SiteContext.Provider value={store}>{children}</SiteContext.Provider>;
};

export default SiteProvider;
