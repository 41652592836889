import { CurrencySymbol } from "../../types/currency";

export const CURRENCY_NAME = {
  [CurrencySymbol.USD]: "US Dollar",
  [CurrencySymbol.AUD]: "Australian Dollar",
  [CurrencySymbol.ETH]: "Ethereum",
  [CurrencySymbol.BTC]: "Bitcoin",
};

export const SUPPORTED_CURRENCIES = [
  CurrencySymbol.USD,
  CurrencySymbol.AUD,
  CurrencySymbol.ETH,
  CurrencySymbol.BTC,
];
