import { SUPPORTED_CURRENCIES } from "../src/constants/currencies";
import { MarketDays } from "../types/coin-market-data";

export const DEFI_GLOBAL_ENDPOINT =
  "https://api.coingecko.com/api/v3/global/decentralized_finance_defi";

export const MARKET_GLOBAL_ENDPOINT = "https://api.coingecko.com/api/v3/global";

const createUrlWithParams = (
  url: string,
  params: { [key: string]: string | number }
) => {
  if (Object.keys(params).length === 0) {
    return url;
  }

  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");

  return `${url}?${queryString}`;
};

interface CoinMarketEndpointOptions {
  pageSize: number;
  page: number;
  ids?: string[];
  hasSparkline?: boolean;
  hasChange?: boolean;
  isDefi?: boolean;
}

export const getCoinListQueryUrl = ({
  pageSize,
  page,
  ids,
  hasSparkline,
  hasChange,
  isDefi,
}: CoinMarketEndpointOptions) => {
  const params: { [key: string]: string | number } = {
    vs_currency: "usd",
    order: "market_cap_desc",
    per_page: pageSize,
    page,
  };

  if (ids) {
    params.ids = ids.join(",");
  }

  if (hasSparkline) {
    params.sparkline = "true";
  }

  if (hasChange) {
    params.price_change_percentage = "24h%2C7d";
  }

  if (isDefi) {
    params.category = "decentralized_finance_defi";
  }

  return createUrlWithParams(
    "https://api.coingecko.com/api/v3/coins/markets",
    params
  );
};

interface ExchangeListEndpointOptions {
  pageSize: 100 | 250;
  page: number;
}

export const getExchangeListQueryUrl = ({
  pageSize,
  page,
}: ExchangeListEndpointOptions) => {
  const params: { [key: string]: string | number } = {
    per_page: pageSize,
    page,
  };

  return createUrlWithParams(
    "https://api.coingecko.com/api/v3/exchanges",
    params
  );
};

interface SimplePriceEndpointOptions {
  ids: string[];
}

export const getSimplePriceQueryUrl = ({ ids }: SimplePriceEndpointOptions) => {
  const params: { [key: string]: string | number } = {
    ids: ids.join(","),
    vs_currencies: SUPPORTED_CURRENCIES.join(","),
    include_last_updated_at: "true",
  };

  return createUrlWithParams(
    "https://api.coingecko.com/api/v3/simple/price",
    params
  );
};

interface CoinDetailEndpointOptions {
  id: string;
}

export const getCoinDetailQueryUrl = ({ id }: CoinDetailEndpointOptions) => {
  const params: { [key: string]: string | number } = {
    localization: "false",
    tickers: "false",
    market_data: "true",
    community_data: "false",
    developer_data: "false",
    sparkline: "true",
  };

  return createUrlWithParams(
    `https://api.coingecko.com/api/v3/coins/${id}`,
    params
  );
};

export const getCoinTickersQueryUrl = ({ id }: CoinDetailEndpointOptions) => {
  const params: { [key: string]: string | number } = {
    include_exchange_logo: "true",
    page: 1, // Maybe add exchange filters
  };

  return createUrlWithParams(
    `https://api.coingecko.com/api/v3/coins/${id}/tickers`,
    params
  );
};

interface CoinMarketDataEndpointOptions {
  id: string;
  days: MarketDays;
}

export const getCoinMarketDataQueryUrl = ({
  id,
  days,
}: CoinMarketDataEndpointOptions) => {
  const params: { [key: string]: string | number } = {
    vs_currency: "usd",
    days,
  };

  return createUrlWithParams(
    `https://api.coingecko.com/api/v3/coins/${id}/market_chart`,
    params
  );
};

export const getExchangeDetailQueryUrl = (id: string) => {
  return `https://api.coingecko.com/api/v3/exchanges/${id}`;
};
