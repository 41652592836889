import React, { ReducerState, ReducerAction, Dispatch, Reducer } from "react";
import { CurrencySymbol } from "../../types/currency";
import { CurrencyAction, initialCurrencyState } from "./store/currency";

type SiteReducer<State, Action> = [
  ReducerState<Reducer<State, Action>>,
  Dispatch<ReducerAction<Reducer<State, Action>>>
];

export interface SiteContext {
  isMobile: boolean;
  isDarkmode: boolean;
  currency: SiteReducer<CurrencySymbol, CurrencyAction>;
  usdExchangeRate?: number;
  btcExchangeRate?: number;
}

const defaultContextValue: SiteContext = {
  isMobile: false,
  isDarkmode: false,
  currency: [initialCurrencyState, () => {}],
};

export const SiteContext = React.createContext<SiteContext>(
  defaultContextValue
);
