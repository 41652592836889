// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-coins-defi-tsx": () => import("./../../../src/pages/coins/defi.tsx" /* webpackChunkName: "component---src-pages-coins-defi-tsx" */),
  "component---src-pages-coins-index-tsx": () => import("./../../../src/pages/coins/index.tsx" /* webpackChunkName: "component---src-pages-coins-index-tsx" */),
  "component---src-pages-coins-tech-analysis-tsx": () => import("./../../../src/pages/coins/tech-analysis.tsx" /* webpackChunkName: "component---src-pages-coins-tech-analysis-tsx" */),
  "component---src-pages-defi-market-tsx": () => import("./../../../src/pages/defi-market.tsx" /* webpackChunkName: "component---src-pages-defi-market-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-exchanges-dex-tsx": () => import("./../../../src/pages/exchanges/dex.tsx" /* webpackChunkName: "component---src-pages-exchanges-dex-tsx" */),
  "component---src-pages-exchanges-index-tsx": () => import("./../../../src/pages/exchanges/index.tsx" /* webpackChunkName: "component---src-pages-exchanges-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-coin-tsx": () => import("./../../../src/templates/coin.tsx" /* webpackChunkName: "component---src-templates-coin-tsx" */),
  "component---src-templates-exchange-tsx": () => import("./../../../src/templates/exchange.tsx" /* webpackChunkName: "component---src-templates-exchange-tsx" */)
}

