import { CurrencySymbol } from "../../../types/currency";

export const initialCurrencyState: CurrencySymbol = CurrencySymbol.USD;

export enum CurrencyActionType {
  SetCurrency = "currency/SetCurrency",
}

export type CurrencyAction = {
  type: CurrencyActionType.SetCurrency;
  currency: CurrencySymbol;
};

export const currencyReducer = (
  state: CurrencySymbol,
  action: CurrencyAction
) => {
  switch (action.type) {
    case CurrencyActionType.SetCurrency:
      return action.currency;

    default:
      return state;
  }
};

export const setCurrency = (currency: CurrencySymbol): CurrencyAction => ({
  type: CurrencyActionType.SetCurrency,
  currency,
});
